import GATSBY_COMPILED_MDX from "/Users/garretcassels/repo/review-request-email/src/articles/ten-review-requests-mistakes-to-avoid.mdx";
import React from "react";
import {Box, Typography} from '@mui/material';
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import {GatsbyImage} from "gatsby-plugin-image";
import "../css/article.css";
function PageTemplate({children, pageContext}) {
  return React.createElement(Layout, null, React.createElement(Box, {
    className: "article",
    sx: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      py: 4,
      px: [1, 2, 2, 4]
    }
  }, React.createElement(Box, {
    sx: {
      display: ['block', 'block', 'block', 'flex'],
      width: '100%',
      maxWidth: '1000px',
      flexDirection: [null, null, null, 'row'],
      justifyContent: [null, null, null, 'space-between'],
      alignItems: [null, null, null, 'flex-start']
    }
  }, React.createElement(Box, {
    sx: {
      maxWidth: '650px',
      flex: '1 1'
    }
  }, React.createElement("h1", null, pageContext.frontmatter.title), React.createElement("img", {
    src: pageContext.frontmatter.header_img
  }), React.createElement(Typography, {
    sx: {
      fontSize: '.9rem',
      color: '#7a7676',
      margin: '20px 0px'
    }
  }, pageContext.frontmatter.date), children), typeof pageContext.tableOfContents.items !== 'undefined' && React.createElement(Box, {
    sx: {
      minWidth: '240px',
      margin: '100px 0px 0px 25px'
    }
  }, React.createElement("h2", null, "Table of Contents"), pageContext.tableOfContents.items.map(i => React.createElement("li", {
    key: i.url
  }, React.createElement("a", {
    href: i.url,
    key: i.url
  }, i.title)))))));
}
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageTemplate, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const Head = ({location, pageContext}) => React.createElement(Seo, {
  title: pageContext.frontmatter.title,
  description: pageContext.frontmatter.blurb,
  url: location.pathname ? location.pathname : null,
  imagePath: pageContext.frontmatter.header_img,
  type: "article"
});
