/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h2: "h2",
    a: "a",
    div: "div",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Start sending more effective review requests."), "\n", React.createElement(_components.p, null, "Boost your conversion rate and get more reviews by avoiding these ten costly mistakes."), "\n", React.createElement(_components.p, null, "Be sure to stick around for a bonus tip at the very end."), "\n", React.createElement(_components.h2, {
    id: "1-spelling",
    style: {
      position: "relative"
    }
  }, "1. Spelling", React.createElement(_components.a, {
    href: "#1-spelling",
    "aria-label": "1 spelling permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Starting with an obvious, but critical point."), "\n", React.createElement(_components.p, null, "Spelling mistakes are unprofessional. They distract the recipient from the point and cause them not to trust the authenticity of the email."), "\n", React.createElement(_components.p, null, "Considering how easy it is to get someone to spellcheck your email and how significant the impact of a spelling mistake can be. This is a quick win."), "\n", React.createElement(_components.h2, {
    id: "2-send-time",
    style: {
      position: "relative"
    }
  }, "2. Send Time", React.createElement(_components.a, {
    href: "#2-send-time",
    "aria-label": "2 send time permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Don't send your request when it is convenient for you to work on them, send them when it is convenient for customers to receive them."), "\n", React.createElement(_components.p, null, "You would be shocked how much better your review request emails will perform if they arrive in your customers inbox at the perfect time for them."), "\n", React.createElement(_components.p, null, "Maybe that means creating them and sending them later, or maybe there is a delayed send feature on your email application you can use."), "\n", React.createElement(_components.h2, {
    id: "3-timing",
    style: {
      position: "relative"
    }
  }, "3. Timing", React.createElement(_components.a, {
    href: "#3-timing",
    "aria-label": "3 timing permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Time related again, but in this case we are talking about proximity to the conclusion of your business."), "\n", React.createElement(_components.p, null, "For service businesses it is important to ensure the email is sent as close to the completion of your business with the customer as possible."), "\n", React.createElement(_components.p, null, "That is when the customer will be most enthralled with your work and when it will be easiest for them to come up with a few words to say about your company."), "\n", React.createElement(_components.p, null, "Product businesses must wait until the product has shipped and the customer has had time to enjoy it before they are asked for a review."), "\n", React.createElement(_components.h2, {
    id: "4-email-application-compatibility",
    style: {
      position: "relative"
    }
  }, "4. Email Application Compatibility", React.createElement(_components.a, {
    href: "#4-email-application-compatibility",
    "aria-label": "4 email application compatibility permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "This is especially important if you are sending HTML emails."), "\n", React.createElement(_components.p, null, "There are countless email applications out there. Gmail, Outlook, Yahoo mail. The same HTML emails can look wildly different in each of those email applications."), "\n", React.createElement(_components.p, null, "Before you send your HTML email test it to make sure it displays correctly in at least a few of the most common email applications."), "\n", React.createElement(_components.p, null, "If you are sending a plain text email this is not a concern."), "\n", React.createElement(_components.h2, {
    id: "5-branding-mismatch",
    style: {
      position: "relative"
    }
  }, "5. Branding Mismatch", React.createElement(_components.a, {
    href: "#5-branding-mismatch",
    "aria-label": "5 branding mismatch permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Make sure your email matches the other parts of your businesses brand the customer has seen."), "\n", React.createElement(_components.p, null, "Key brand components in an email are logo and color, but the writing style in the subject and body of the email could also be a factor for more established brands."), "\n", React.createElement(_components.p, null, "A strong brand match will increase the familiarity of the email and help the customer trust the email is legitimate."), "\n", React.createElement(_components.h2, {
    id: "6-mobile--desktop-friendly",
    style: {
      position: "relative"
    }
  }, "6. Mobile / Desktop Friendly", React.createElement(_components.a, {
    href: "#6-mobile--desktop-friendly",
    "aria-label": "6 mobile  desktop friendly permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "This point also only pertains to HTML emails."), "\n", React.createElement(_components.p, null, "An HTML email that looks good on a desktop computer or laptop (wider screens) may not look as good on a mobile phone or tablet where the screen size is smaller."), "\n", React.createElement(_components.p, null, "Be sure to view your email on a few screen sizes before you send it to customers."), "\n", React.createElement(_components.p, null, "If you are sending a plain text email this is not a concern."), "\n", React.createElement(_components.h2, {
    id: "7-follow-up",
    style: {
      position: "relative"
    }
  }, "7. Follow up", React.createElement(_components.a, {
    href: "#7-follow-up",
    "aria-label": "7 follow up permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "If you are not following up with customers that don't post a review after your initial email then you are missing out on reviews."), "\n", React.createElement(_components.p, null, "Just think, it is entirely possible the customer wanted to post a review, but your initial email reached them when they were distracted."), "\n", React.createElement(_components.p, null, "Here are three things to remember when following up with customers:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "Don't pester the customer"), "\n", React.createElement(_components.li, null, "Stop emailing them once they have posted a review"), "\n", React.createElement(_components.li, null, "Give the customer the ability to opt out of receiving further emails"), "\n"), "\n", React.createElement(_components.p, null, "One reminder is great, two is better."), "\n", React.createElement(_components.h2, {
    id: "8-email-subject",
    style: {
      position: "relative"
    }
  }, "8. Email Subject", React.createElement(_components.a, {
    href: "#8-email-subject",
    "aria-label": "8 email subject permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "This is the first thing your customer will see. It is the top of the funnel and the first opportunity to miss out on a review."), "\n", React.createElement(_components.p, null, "A bad email subject is like sprucing up the interior of a business, but not the storefront. No one will see what you have done if they don't open the door."), "\n", React.createElement(_components.p, null, "We have a whole article full of tips and tricks for ", React.createElement("a", {
    href: "/articles/subject-lines-for-review-request-emails",
    title: "Review request email subject article"
  }, "review request email subject lines"), ". The article also includes multiple examples."), "\n", React.createElement(_components.h2, {
    id: "9-email-body",
    style: {
      position: "relative"
    }
  }, "9. Email Body", React.createElement(_components.a, {
    href: "#9-email-body",
    "aria-label": "9 email body permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Keep it short and to the point. You can be flattering, cute or whatever else you think your customers will like, but just make sure you do it with an appropriate word count."), "\n", React.createElement(_components.p, null, "Asking your customer to read a novel before you get to your point is just another point of friction that may be the difference between them posting a review or not."), "\n", React.createElement(_components.h2, {
    id: "10-review-link",
    style: {
      position: "relative"
    }
  }, "10. Review Link", React.createElement(_components.a, {
    href: "#10-review-link",
    "aria-label": "10 review link permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The easier you can make it for a custom to post a review the better your chances are they will follow through."), "\n", React.createElement(_components.p, null, "Having a link to your review site of choice is an absolute must. Make sure your email clearly explains where the link will take them."), "\n", React.createElement(_components.p, null, "Ideally the link will take the customer directly to the review submission form, not just the site. Any additional clicking or navigation increases the friction to posting a review."), "\n", React.createElement(_components.h2, {
    id: "bonus-tip",
    style: {
      position: "relative"
    }
  }, "Bonus Tip", React.createElement(_components.a, {
    href: "#bonus-tip",
    "aria-label": "bonus tip permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "You can do everything else on the list correctly and it will all be for not if you aren’t providing fantastic customer service."), "\n", React.createElement(_components.p, null, "The journey towards collecting a customer review starts at your first interaction with a customer. It continues any time that customer interacts with your business."), "\n", React.createElement(_components.p, null, "Review requests do not get positive reviews out of unhappy customers. They help ensure you collect as many reviews as possible from hard won happy customers."), "\n", React.createElement(_components.p, null, "Make sure you and all your employees remember this at all times."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
